<template>
	<div class="sensitive">
		<div class="title">
			{{ $route.meta.title }}
			<i @click="showCourse = !showCourse" class="iconfont icon-question-circle"></i>
		</div>
		<div class="course" v-show="showCourse">
			<p><span class="black">1、可根据企业内部制定的聊天规范，设置相关敏感词，并设置敏感词使用的成员及提醒对象。</span></p>
		</div>
		<div class="flex">
			<el-input size="small" v-model="keyword" style="width: 200px;margin-right: 10px;" placeholder="请输入敏感词"></el-input>
			<el-button class="btn-blue" size="small" @click="handleSearch">搜索</el-button>
			<el-button class="btn-blue right" size="small" @click="visible = true">添加敏感词</el-button>
		</div>
		<div class="table">
			<el-table
				v-loading="loading"
				:data="tableData"
				style="width: 100%"
				:header-cell-style="{
					color: '#333',
					fontSize: '14px',
					backgroundColor: '#F6F7FB',
					fontWeight: 'normal'
				}"
			>
				<el-table-column prop="content" label="敏感词"></el-table-column>
				<el-table-column label="适用成员">
					<template v-slot="scope">
						{{ scope.row.staff_user_id.join(',') || '--' }}
					</template>
				</el-table-column>
				<el-table-column label="通知成员">
					<template v-slot="scope">
						{{ scope.row.notice_staff.join(',') || '--' }}
					</template>
				</el-table-column>
				<el-table-column prop="add_user" label="创建人"></el-table-column>
				<el-table-column prop="create_at" label="创建时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
						<el-button type="text" @click="handleDel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination" v-if="total !== 0">
			<el-pagination
				@size-change="
					pageSize => {
						limit = pageSize;
						page = 1;
						getList();
					}
				"
				@current-change="
					currentPage => {
						page = currentPage;
						getList();
					}
				"
				:current-page="page"
				:page-sizes="[10, 20, 40, 80]"
				:page-size="limit"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
		<!-- 添加、编辑敏感词 -->
		<el-dialog
			:title="`${form.id ? '编辑敏感词' : '添加敏感词'}`"
			:visible.sync="visible"
			:close-on-click-modal="false"
			width="500px"
			:before-close="
				() => {
					visible = false;
				}
			"
		>
			<el-form label-position="left" label-width="120px" :model="form" ref="form" :rules="rules" v-loading="form.loading">
				<el-form-item label="敏感词" prop="word"><el-input size="small" v-model="form.word" placeholder="请输入敏感词"></el-input></el-form-item>
				<el-form-item label="适用成员">
					<select-staff :showValue="form.showStaff" v-show="!form.isStaffAll" className="staff" placeholder="请选择成员" :multiple="true" @getStaff="getStaff" />
					<el-checkbox :true-label="1" :false-label="0" v-model="form.isStaffAll">全部成员</el-checkbox>
				</el-form-item>
				<el-form-item label="适用群聊">
					<select-group :showValue="form.showGroup" v-show="!form.isGroupAll" className="group" placeholder="请选择群聊" :multiple="true" @getGroup="getGroup" />
					<el-checkbox :true-label="1" :false-label="0" v-model="form.isGroupAll">全部群聊</el-checkbox>
				</el-form-item>
				<el-form-item label="违规通知成员" prop="notice_staff">
					<select-staff :showValue="form.showNotiveStaff" className="notice-staff" placeholder="请选择违规通知成员" :multiple="true" @getStaff="getNoticeStaff" />
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button size="small" class="btn-white" @click="visible = false">取 消</el-button>
				<el-button size="small" class="btn-blue" @click="handleSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { reqGetCustomGroupList, reqGetSensitiveWordsList, reqAddSensitiveWords, reqEditSensitiveWords, reqShowSensitiveWords, reqDelSensitiveWords } from '@/api/index';
import SelectStaff from '@/components/SelectStaff.vue';
import SelectGroup from '@/components/SelectGroup.vue';
export default {
	components: {
		SelectStaff,
		SelectGroup
	},
	data() {
		return {
			showCourse: false,
			keyword: '',
			page: 1,
			limit: 10,
			total: 0,
			loading: false,
			tableData: [],
			visible: false,
			form: {
				staff: [],
				notice_staff: [],
				group: [],
				word: '',
				isStaffAll: 0,
				isGroupAll: 0,
				showStaff: [],
				showGroup: [],
				showNotiveStaff: [],
				loading: false,
				id: ''
			},
			rules: {
				word: [{ required: true, message: '请输入敏感词', trigger: 'blur' }],
				notice_staff: [{ required: true, message: '请选择违规通知成员', trigger: 'change' }]
			}
		};
	},
	watch: {
		visible(val) {
			if (!val) {
				this.$refs.form.resetFields();
				Object.assign(this.$data.form, this.$options.data().form);
			}
		},
		keyword(val) {
			if (!val) {
				this.page = 1;
				this.getList();
			}
		},
		total(val) {
			if (val > 0 && this.tableData.length === 0) {
				this.page = parseInt(val / this.limit) + (val % this.limit > 0 ? 1 : 0);
				this.getList();
			}
		}
	},
	created() {
		this.getList();
	},
	methods: {
		handleSearch() {
			if (this.keyword) {
				this.page = 1;
				this.getList();
			}
		},
		getGroupList() {
			reqGetCustomGroupList({
				page: 1
			});
		},
		getList() {
			this.loading = true;
			reqGetSensitiveWordsList({
				page: this.page,
				limit: this.limit,
				keyword: this.keyword
			}).then(res => {
				this.tableData = res.data;
				this.total = res.count || 0;
				this.loading = false;
			});
		},
		// 添加、编辑敏感词
		addSensitive(params) {
			reqAddSensitiveWords(params).then(res => {
				this.getList();
				this.$message.success(res.msg);
				this.visible = false;
			});
		},
		// 回显敏感词
		showSensitive(id) {
			this.form.loading = true;
			reqShowSensitiveWords({
				id
			}).then(res => {
				this.form.word = res.data.content;
				this.form.showNotiveStaff = res.data.notice_staff.split(',');
				if (res.data.staff_user_id === '1') {
					this.form.isStaffAll = 1;
				} else if (res.data.staff_user_id) {
					this.form.showStaff = res.data.staff_user_id.split(',');
				}
				if (res.data.chat_group_id === '1') {
					this.form.isGroupAll = 1;
				} else if (res.data.chat_group_id) {
					this.form.showGroup = res.data.chat_group_id.split(',');
				}
				this.form.id = res.data.id;
				this.form.loading = false;
			});
		},
		visibleGroup(flag) {
			this.$nextTick(() => {
				const oScroll = document.querySelector('.select-group .el-scrollbar__wrap');
				if (flag)
					oScroll.addEventListener('mousewheel', () => {
						if (oScroll.scrollTop + oScroll.clientHeight == oScroll.scrollHeight) {
							if (!this.staff.loading && this.staff.list.length < this.staff.total) {
								this.staff.limit += 10;
								this.getStaffList();
							}
						}
					});
			});
		},
		getStaff(val) {
			this.form.staff = val;
		},
		getNoticeStaff(val) {
			this.form.notice_staff = val;
		},
		getGroup(val) {
			this.form.group = val;
		},
		handleEdit(row) {
			this.visible = true;
			this.showSensitive(row.id);
		},
		handleDel(row) {
			this.$confirm(`确认删除敏感词【${row.content}】？`, '删除敏感词', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					reqDelSensitiveWords({ id: row.id }).then(res => {
						this.$message.success(res.msg);
						this.getList();
					});
				})
				.catch(() => {});
		},
		handleSubmit() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					if (this.form.isGroupAll == 1 || this.form.isStaffAll == 1 || this.form.staff.length || this.form.group.length) {
						const params = {};
						params.content = this.form.word;
						params.staff_user_id = this.form.isStaffAll == 1 ? 1 : this.form.staff.join(',');
						params.chat_group_id = this.form.isGroupAll == 1 ? 1 : this.form.group.join(',');
						params.notice_staff = this.form.notice_staff.join(',');
						params.add_user = JSON.parse(localStorage.getItem('huankemao_user_info')).phone;
						this.form.id ? (params.id = this.form.id) : '';
						this.addSensitive(params);
					} else {
						this.$message.error('至少选择一项适用目标');
						return false;
					}
				} else {
					return false;
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
.sensitive {
	width: 100%;
	position: relative;
	overflow: hidden;

	.flex {
		display: flex;
	}

	> div + div {
		margin-top: 15px;
	}
}
</style>
